export const Storage = [
  {
    left: "Edge Storage",
    right: "Network Remote Storage; micro SD card storage",
  },
  {
    left: "Network Storage",
    right: "Built-in micro SD card slot, up to 128GB/256GB",
  },
];
