import React from "react";
import CommonCompo from "../CommonCompo/CommonCompo";

function NetworkVideoRec() {
  return (
    <div>
      <CommonCompo />
    </div>
  );
}

export default NetworkVideoRec;
