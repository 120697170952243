import React from "react";
import CommonCompo from "../CommonCompo/CommonCompo";

const HDAnalog = () => {
  return (
    <div>
      <CommonCompo />
    </div>
  );
};

export default HDAnalog;
