import React from "react";
import CommonCompo from "../CommonCompo/CommonCompo";

export const Accesories = () => {
  return (
    <div>
      <CommonCompo />
    </div>
  );
};
