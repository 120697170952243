import React from "react";
import "./NetworkingCameras.css";

import "bootstrap/dist/css/bootstrap.min.css";
import CommonCompo from "../CommonCompo/CommonCompo";

export const NetworkingCameras = () => {
  return (
    <div>
      <CommonCompo />
    </div>
  );
};
