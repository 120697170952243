export const Interface = [
  {
    left: "Audio I/O",
    right: "1CH audio input",
  },
  {
    left: "Network",
    right: "1 RJ45 10M/100M Base-TX Ethernet",
  },
  {
    left: "Built-in Mic",
    right: "1-ch Built-in Mic",
  },
];
