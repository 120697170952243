import React from "react";
import CommonCompo from "../CommonCompo/CommonCompo";

const Codec = () => {
  return (
    <div>
      <CommonCompo />
    </div>
  );
};

export default Codec;
